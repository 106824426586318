@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

html,
body {
  background-color: #f9fafe !important; 
  font-family: "Roboto", sans-serif;
}
.navbar-collapse {
  text-align: center !important;
}
.primary-bg {
  background-color: #004043;
  color: #ffffff;
  border: none;
}

.primary-fg {
  color: #004043;
}

.secondary-bg {
  background: #0040431a !important;
  color: #004043;
  border: none;
}

.main-container {
  background-color: #f9fafe;
  width: 100%;
  height: 100vh;
}

.center-card {
  background-color: #ffffff;
  box-shadow: 0px 0px 25px rgba(22, 22, 22, 0.031);
  border-radius: 10px;
}

.textField {
  border: 1px solid black;
  border-radius: 0px;
}

.bg-none {
  background: white;
}

a {
  text-decoration: none !important;
  color: #12151b99;
}

.btn {
  border-radius: 5px !important;
}

.cursor-pointer {
  cursor: pointer;
}
.link-active {
  background-color: #004043;
  border-radius: 5px;
  padding: 14px 0px;
}
.link-active > div > a {
  color: white !important;
}
.link-active > svg > path {
  fill: white;
}
.no-fill > path {
  fill: #717376 !important;
}

.loader {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader > .spinner-grow{
  color:#000 !important;
  width: 3rem;
  height: 3rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}