@font-face {
  font-family: "Poppins-Bold";
  src: url("../../fonts/Poppins-Bold.ttf");
}
.cNStyle {
  background: #0040430d;
  color: #12151b !important;
  font-weight: 500;
  font-size: 15px;
}

.divider-list {
  background: rgba(0, 0, 0, 0.1);
  height: 1px;
  margin: 5px 0px;
}

.actionButton {
  background: rgba(8, 68, 68, 0.15);
  border: none;
  width: 34px;
  height: 34px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.comparison-review-btn {
  padding: 14px 30px;
}
.comparison-checkbox {
  display: flex;
  align-items: center;
}
.comparison-checkbox > input {
  width: 25px;
  height: 25px;
  border: 1px solid;
}
.comparison-checkbox > input:checked {
  background-color: #004043 !important;
  border-color: #004043 !important;
}
.comparison-checkbox > input:focus {
  border-color: #004043 !important;
  box-shadow: none !important;
}
.comparison-checkbox > label {
  margin-left: 10px;
}
.nameLabel-report {
  font-weight: 700;
  font-family: "Poppins-Bold";
  padding: 5px;
}

.accordian {
  border: none !important;
}

.tableHeader {
  background: #084444;
  color: white;
  border-radius: 10px !important;
}
.table {
  border-radius: 10px !important;
}
.mid-row {
  background-color: #4593b81a !important;
  text-align: center !important;
  color: #12151b !important;
  font-weight: 500 !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 5%) !important;
  height: 44px !important;
  font-size: 18px !important;
  padding: 0 !important;
}

.field_name {
  text-transform: capitalize;
}
ul li {
  display: block;
}

.table.b-table.b-table-stacked {
  display: block;
  width: 100%;
}

table.b-table.b-table-stacked>tbody, .table.b-table.b-table-stacked>tbody>tr, .table.b-table.b-table-stacked>tbody>tr>td, .table.b-table.b-table-stacked>tbody>tr>th {
  display: block;
}
.table.b-table.b-table-stacked>tbody>tr>:first-child, .table.b-table.b-table-stacked>tbody>tr>[rowspan]+td, .table.b-table.b-table-stacked>tbody>tr>[rowspan]+th {
  border-top-width: 3px;
}
