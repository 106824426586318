@font-face {
  font-family: "Poppins-Bold";
  src: url("../../fonts/Poppins-Bold.ttf");
}
ol {
  list-style: none;
  counter-reset: my-awesome-counter;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
ol li {
  counter-increment: my-awesome-counter;
  display: flex;
  flex-direction: column;
  width: fit-content;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}
ol li::before {
  content: "0" counter(my-awesome-counter);
  font-weight: bold;
  font-family: "Poppins-Bold";
  font-size: 3rem;
  margin-right: 0.5rem;
  font-family: "Abril Fatface", serif;
  line-height: 1;
}

.link {
  display: flex;
  align-items: center;
  color: #004043 !important;
  margin-top: 10px;
  gap: 10px;
}
.link span {
  font-size: 14px;
  font-weight: bold;
  color: #000;
  font-family: "Poppins-Bold";
}
.link a:hover {
  color: #004043 !important;
  opacity: 1;
}
