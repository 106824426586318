@media print {
    .blood-count-accordions {
        max-height: 100% !important;
        padding-left: 10px;
        overflow: hidden !important;
    }
}
.font-12{
    font-size: 12px;
}
.masterlist-modal{
    background-color: #000;
 }
 