@font-face {
  font-family: "Poppins-Bold";
  src: url("../../fonts/Poppins-Bold.ttf");
}
.section-label {
  font-weight: 700;
  color: black;
  font-size: 15px;
  font-family: "Poppins-Bold";
}

.item-label {
  font-weight: 600;
  color: black;
  font-size: 15px;
}
