@font-face {
  font-family: "Poppins";
  src: url("../../fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("../../fonts/Poppins-Bold.ttf");
}
* {
  font-family: "Poppins";
}
.fw-bold{
  font-family: "Poppins-Bold";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.font-16{
  font-size: 16px;
}
.btn-primary:hover {
  background-color: #d0e1e9 !important;
}
textarea {
  resize: none;
}
a:hover {
  color: #d0e1e9 !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-36 {
  margin-bottom: 36px !important;
}
.mb-26 {
  margin-bottom: 26px !important;
}
.op-1 {
  opacity: 1 !important;
}
.dark-grey {
  color: #12151b !important;
}
.sidebar {
  border-radius: 15px;
  background-color: white;
  color: black;
  height: 100%;
  min-width: 280px;
  padding: 0 20px;
}

.rws-logo-sidebar {
  width: 180px;
  padding: 20px;
  padding-top: 64px;
  padding-bottom: 35px;
}

.divider {
  background: rgba(0, 0, 0, 0.1);
  height: 1px;
  margin: 5px 14px;
}

.sidebarIcon {
  padding: 5px;
}

.sideMenuItem {
  padding: 10px !important;
}

/* .navbar-nav .nav-link:last-child{
  padding-right: 30px;
} */
.header_navbar {
  padding-bottom: 30px !important;
}
.btn-help {
  margin-right: 30px !important;
  font-weight: 400 !important;
}
.header_navbar a .btn {
  height: 50px;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
}
.main-container {
  padding-bottom: 20px;
  min-height: 100vh;
  height: unset;
}
.mainDiv > div {
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 30px !important;
}

.nav_item {
  height: 50px;
  margin-bottom: 10px;
  padding: 0 20px !important;
}
.nav_item a {
  color: #12151b;
  opacity: 0.6;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.nav_item.link-active .sidebar_text {
  color: #fff;
}

.nav_item:hover .sidebar_text,
.nav_item:hover a {
  color: #004043;
  opacity: 1;
}

.nav_item.link-active a {
  opacity: 1;
}
.nav_item.link-active a .sidebar_text {
  color: #fff;
}
.nav_item.link-active .sidebar_icon svg path {
  fill: #fff;
}
.nav_item .sidebar_icon svg {
  margin-right: 0 !important;
}
.nav_item .sidebar_icon {
  margin-right: 15px;
  min-width: 26px;
}
.main-content {
  background-color: #fff;
  padding: 30px;
  box-shadow: 0px 0px 25px rgba(22, 22, 22, 0.031);
  border-radius: 10px;
  min-height: 100vh;
  height: unset;
}
.client_search .form-control {
  background: #f9fafe;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-weight: 500;
  font-size: 20px;
  color: #12151b;
  opacity: 0.8;
  min-height: 61px;
  padding-left: 25px;
}
.client_search {
  margin-bottom: 39px;
}

.client_link .cNStyle {
  height: 56px;
  background: #0040430d;
  font-weight: 500;
  font-size: 16px;
  color: #12151bcc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-left: 30px;
}
.client_name {
  font-weight: 500;
  font-size: 16px;
  text-decoration: underline;
  color: #004043;
  padding-left: 30px;
}
.client_name a:hover {
  color: #004043;
}
.client_name > div {
  margin: 18px 0;
}
.client_name .divider-list {
  margin-left: -30px;
}
.form-control:focus,
.btn:focus {
  box-shadow: unset !important;
  border-color: #12151b !important;
}
.form_checkbox .form-check-label a {
  text-decoration: underline;
}
.form-card {
  padding: 30px;
}
.form-card label {
  font-weight: 500;
  font-size: 16px;
  color: #12151b;
  opacity: 0.8;
}
.form-card .form-control,
.form-card .form-select {
  background-color: #f9fafe;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgb(18, 21, 27, 0.6);
}
.form-card .mb-3 {
  margin-bottom: 10px !important;
}
.form-card .next-btn {
  background: #004043;
  border-radius: 5px;
  height: 54px;
  max-width: 218px;
  margin: auto;
  display: block;
  margin-top: 57px;
  margin-bottom: 22px;
}
.acSet {
  font-weight: 600;
  font-size: 24px;
  color: #12151b;
  opacity: 0.8;
}
.setting-form .acSet {
  padding-bottom: 24px;
}

.setting-form label {
  font-weight: 500;
  font-size: 16px;
  color: #12151b;
  opacity: 0.8;
  margin-bottom: 8px !important;
}
.setting-form .form-control {
  background: #f9fafe;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  height: 50px;
}
.setting-form .field-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 50px;
  width: 125px;
}
.setting-form {
  padding: 30px;
}
.account-btn {
  display: block;
  margin: 0 auto;
  margin-top: 36px;
  width: 262px !important;
  height: 54px;
}
.btn:disabled {
  background: #004043 !important;
  opacity: 0.5;
}
.setting-form {
  padding-bottom: 72px;
}
.save-submit {
  width: 218px !important;
  height: 54px;
}

.blood-count-section {
  padding: 0 20px 0 20px;
  margin-bottom: 40px;
}
.blood-count-section .section-label {
  margin-bottom: 26px;
  font-weight: 600;
  font-size: 20px;
  color: #12151b;
}
.blood-count-section .item-label {
  font-weight: 500;
  font-size: 16px;
  color: #12151b;
  opacity: 0.8;
  min-width: 160px;
  margin-right: 8px;
  display: block;
  max-width: 160px;
  line-height: 1.4;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blood-count-section .form-select {
  max-width: 150px;
  height: 46px;
  padding-right: 27px;
  background-color: #f9fafe;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-right: 22px;
  font-weight: 400;
  font-size: 16px;
  color: rgb(18, 21, 27, 0.6);
  padding-left: 16px;
}
.blood-count-section .form-control {
  height: 46px;
  background: #f9fafe;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  color: rgb(108 117 125);
  padding-left: 30px;
}
.blood-count-section .blood-detail-section {
  margin-bottom: 30px;
}
.scroll-div {
  margin-right: 0px;
  padding-right: 20px;
}

.scroll-div::-webkit-scrollbar,
.blood-count-accordions::-webkit-scrollbar,
.tableScroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.scroll-div::-webkit-scrollbar-track,
.blood-count-accordions::-webkit-scrollbar-track,
.tableScroll::-webkit-scrollbar-track {
  background-color: rgb(8 68 68 / 10%);
}

.scroll-div::-webkit-scrollbar-thumb,
.blood-count-accordions::-webkit-scrollbar-thumb,
.tableScroll::-webkit-scrollbar-thumb {
  background: #084444;
  width: 6px;
  height: 10px;
  border-radius: 20px;
}
.scroll-div::-webkit-scrollbar-button,
.blood-count-accordions::-webkit-scrollbar-button,
.tableScroll::-webkit-scrollbar-button {
  background-color: rgb(8 68 68 / 10%);
  background-size: 6px 10px;
  background-repeat: no-repeat;
  background-position: center center;
  height: 16px;
  width: 1em;
}
.scroll-div::-webkit-scrollbar-button:horizontal:increment,
.blood-count-accordions::-webkit-scrollbar-button:horizontal:increment,
.tableScroll::-webkit-scrollbar-button:horizontal:increment {
  background-image: url(https://dl.dropboxusercontent.com/u/55165267/icon2.png);
}
.scroll-div::-webkit-scrollbar-button:end:increment,
.blood-count-accordions::-webkit-scrollbar-button:end:increment,
.tableScroll::-webkit-scrollbar-button:end:increment {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQwNC4zMDggNDA0LjMwOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDA0LjMwOCA0MDQuMzA5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTAsMTAxLjA4aDQwNC4zMDhMMjAyLjE1MSwzMDMuMjI5TDAsMTAxLjA4eiIgZmlsbD0iIzAwMDAwMCIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
}
.scroll-div::-webkit-scrollbar-button:start:decrement,
.blood-count-accordions::-webkit-scrollbar-button:start:decrement,
.tableScroll::-webkit-scrollbar-button:start:decrement {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDI1NSAyNTUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI1NSAyNTU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8ZyBpZD0iYXJyb3ctZHJvcC11cCI+CgkJPHBvbHlnb24gcG9pbnRzPSIwLDE5MS4yNSAxMjcuNSw2My43NSAyNTUsMTkxLjI1ICAgIiBmaWxsPSIjMDAwMDAwIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
}

.manual-pdf-section {
  padding: 40px 20px;
}
.upload-pdf {
  margin-bottom: 53px;
  height: 65px;
  font-weight: 500;
  font-size: 20px;
  width: 298px;
}
.manual-btn {
  font-weight: 500;
  font-size: 20px;
  width: 400px;
  height: 110px;
}
.manual-btn-2 {
  font-weight: 500;
  font-size: 20px;
  width: 298px;
  height: 65px;
}

.upload-pdf ~ span {
  top: 0;
  right: 10%;
  border: 1px solid #004043;
  color: #004043;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
}

.tab-btn {
  height: 60px;
  background: #ffffff !important;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}
.generate-btn {
  height: 56px;
  background: #ffffff !important;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}
.maker-analysis .btn.primary-bg {
  background-color: #004043 !important;
}

.result-accordion .nameLabel {
  font-weight: 600;
  font-size: 30px;
  color: #12151b;
  padding: 0 12px !important;
  line-height: 45px;
  margin-bottom: 32px;
}
.result-accordion {
  margin-top: -77px;
}
.maker-accordion {
  margin-bottom: 37px;
}
.maker-accordion .accordion-item {
  border: 0 !important;
  box-shadow: 0px 0px 25px rgba(22, 22, 22, 0.031);
  border-radius: 10px;
}
.maker-accordion .accordion-button,
.blood-sugar .accordion-button {
  padding: 30px;
  font-weight: 600;
  font-size: 20px;
  color: rgba(18, 21, 27, 0.8);
}
.accordion-button {
  box-shadow: unset !important;
}
.maker-accordion .accordion-button,
.accordion-button:not(.collapsed) {
  background: #fff !important;
}
.accordion-button:not(.collapsed)::after {
  filter: grayscale(1);
}
.maker-accordion .accordion-body {
  padding: 0 30px;
  padding-bottom: 30px;
}
.maker-accordion .accordion-body textarea {
  background: #f9fafe;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.02em;
  color: rgba(18, 21, 27, 0.7);
}
.maker-accordion .accordion-body .btn {
  background: rgba(0, 64, 67, 0.1);
  border-radius: 5px !important;
  font-weight: 500;
  font-size: 16px;
  color: #084444;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 54px;
  margin-top: 30px;
  width: 160px;
}
.accordion-section .btn-export,
.comparison-accordions .btn-export {
  width: 218px;
  height: 54px;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 36px;
}
.cbc-section {
  padding: 30px;
  padding-left: 20px;
}
.cbc-section .nameLabel {
  font-weight: 600;
  font-size: 20px;
  color: #12151b;
  margin-bottom: 0;
}
.cbc-section .form-label {
  font-weight: 500;
  font-size: 16px;
  color: #12151b;
  opacity: 0.8;
  margin-right: 20px;
  white-space: nowrap;
}
.sortSelect > .mb-3 {
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
  padding-left: 12px;
}
.sortSelect .textfield-label {
  margin-right: 12px;
  margin-bottom: 0 !important;
}
.sortSelect .form-select {
  background-color: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  height: 54px;
  padding-left: 20px;
  font-weight: 400;
  font-size: 16px;
  color: rgba(18, 21, 27, 0.6);
  width: 218px;
}
.blood-count-accordions {
  padding-left: 10px;
}
.blood-count-accordions .accordion {
  margin-bottom: 10px;
  margin-right: 20px;
}
.blood-count-accordions .accordion-item {
  border: 0;
  background: #f9fafe;
}
.blood-count-accordions .accordion-button {
  box-shadow: unset !important;
  font-weight: 600;
  font-size: 20px;
  padding: 15px 22px;
  line-height: 30px;
  color: #12151b;
}
.accordion-button:not(.collapsed) {
  box-shadow: unset !important;
}
.unit-dropdown {
  max-width: 100% !important;
  margin: 0px !important;
}
.blood-count-accordions .accordion-button.collapsed {
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08) !important;
}
.blood-count-accordions .yellowAccordion .accordion-button {
  background: rgba(228, 174, 35, 0.2) !important;
}
.blood-count-accordions .redAccordion .accordion-button {
  background: rgba(233, 71, 55, 0.2) !important;
}
.blood-count-accordions .otherAccordion .accordion-button {
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08) !important;
}
.blood-count-accordions
  .yellowAccordion
  .accordion-button:not(.collapsed)::after,
.blood-count-accordions .redAccordion .accordion-button:not(.collapsed)::after,
.blood-sugar .accordion-button:not(.collapsed)::after {
  filter: brightness(0) invert(1);
}

.blood-count-accordions .accordion-body ul {
  list-style: none;
  padding-left: 30px;
  padding-right: 12px;
}
.blood-count-accordions .accordion-body ul li {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: rgba(18, 21, 27, 0.6);
  margin-bottom: 10px;
}
.arrow {
  min-width: 70px;
  max-height: 70px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.arrow-width {
  width: 32px !important;
  height: 32px !important;
}
.yellow-bg {
  background-color: rgba(228, 174, 35, 0.2) !important;
}
.red-bg {
  background-color: rgba(233, 70, 55, 0.1);
}
.yellow-arrow path {
  fill: rgba(228, 174, 35, 1) !important;
}
.red-arrow path {
  fill: rgba(233, 71, 55, 1) !important;
}
.blood-count-accordions .accordion-body .border-right {
  border-right: 1px solid #000;
}
.symptoms ul {
  padding-left: 75px !important;
}
.symptoms ul li {
  margin-bottom: 22px !important;
  color: #000 !important;
  /* font-weight: 600 !important; */
}
.blood-count-accordions .accordion-body ul li:last-child {
  margin-bottom: 0;
}
.blood-count-accordions .accordion-body .functional ul li:nth-child(2n + 2) {
  margin-bottom: 17px !important;
}
.blood-count-accordions .accordion-body {
  padding: 30px;
}
.blood-sugar .login-card {
  padding: 30px;
}
.blood-sugar .nameLabel {
  padding: 0 !important;
  margin-bottom: 28px !important;
}
.blood-sugar .accordion {
  margin-bottom: 33px;
  background: #f9fafe;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.blood-sugar .accordion-button {
  padding: 12px 18px;
  font-size: 18px;
}
.blood-sugar .accordion-item {
  border: 0;
  background: #f9fafe;
}
.blood-sugar .accordion-body ul {
  list-style-type: none;
  text-align: center;
}
.blood-sugar .accordion-body ul li {
  font-weight: 200;
  font-size: 16px;
  line-height: 33px;
  color: #12151b;
}

.blood-sugar .accordion.redLight .accordion-button,
.blood-sugar .accordion.redLight .accordion-button:not(.collapsed)::after {
  background-color: rgba(228, 174, 35, 0.25) !important;
}
.blood-sugar .accordion.blueLight .accordion-button,
.blood-sugar .accordion.blueLight .accordion-button:not(.collapsed)::after {
  background-color: #d0e1e9 !important;
}
.blood-sugar .accordion.redLight .accordion-button:not(.collapsed)::after,
.blood-sugar .accordion.blueLight .accordion-button:not(.collapsed)::after {
  filter: brightness(0) invert(1);
  background-color: transparent !important;
}
.leftList {
  margin-right: 90px;
}
.comparison-accordions .accordion-item {
  border: 0;
  margin-bottom: 16px;
}
.comparison-accordions .accordion-button {
  height: 78px;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(22, 22, 22, 0.031);
  border-radius: 10px;
  font-weight: 600;
  font-size: 20px;
  color: rgba(18, 21, 27, 0.8) !important;
}
.comparison-accordions .accordion-body {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: rgba(18, 21, 27, 0.7);
  padding: 19px 50px 48px;
}
.comparison-accordions .nameLabel {
  font-size: 30px;
  color: #12151b;
}
.comparison-heading {
  margin-top: 70px;
}

.comparison-table {
  padding: 30px;
  padding-right: 17px;
}
.comparison-table table {
  border-radius: 0 !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  overflow: hidden;
  margin-bottom: 0;
}
.comparison-table table tr td ul {
  list-style-type: none;
  padding-left: 20px;
}
.comparison-table table tr td ul li {
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: rgba(18, 21, 27, 0.8);
  margin-bottom: 5px;
}
.comparison-table table tr th {
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  vertical-align: middle;
  height: 60px;
  padding: 0 4px;
}
.comparison-table table tr td:first-child {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #12151b;
}
.comparison-table table tr td {
  vertical-align: middle;
  text-align: center;
}
.comparison-table table tr td ul li:nth-child(2n + 2) {
  margin-bottom: 20px !important;
}
.comparison-table table tr .boldNumber {
  font-weight: 600;
  font-size: 20px;
  line-height: 45px;
  color: #12151b;
  text-align: end;
  padding-right: 28px;
  padding-left: 28px;
}
.comparison-table table tr td .circle,.b-table-stacked tr td .circle {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  color: #12151b;
  margin: auto;
}
.comparison-table table tr td .circle img ,.b-table-stacked tr td .circle img {
  width: 15px;
  margin-right: 2px;
}
.comparison-table table tr td .lightYellow,.b-table-stacked tr td .lightYellow {
  background: rgba(228, 173, 35, 0.2);
}
.comparison-table table tr td .lightRed,.b-table-stacked tr td .lightRed  {
  background: rgba(233, 70, 55, 0.2);
}

.pattern-table table tr td {
  font-size: 16px;
  line-height: 27px;
  color: #212529;
  vertical-align: middle;
  text-align: center;
  height: 110px;
}
.pattern-table table tr td:first-child {
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  color: #12151b;
  opacity: 1;
  text-align: start;
  padding-left: 16px;
}
.comparison-table.pattern-table table tr td ul li {
  margin-bottom: 0 !important;
  font-size: 15px !important;
}
.tableScroll {
  height: 1114px;
  overflow: overlay;
  margin-top: 36px;
}

.save_action {
  padding-left: 30px !important;
  padding-right: 44px !important;
  height: 56px !important;
  font-weight: 500;
  font-size: 16px;
  margin-top: 22px;
}

.py-13px {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}
.notification{
  background-color: #004043 !important;
  left: 0;
  right: 0;
}
@media (min-width: 1500px) {
  .scroll-div {
    margin-right: 32px;
    padding-right: 20px;
  }
  .blood-count-section {
    padding: 0 32px 0 32px;
    margin-bottom: 80px;
  }
  .blood-count-section .form-select {
    max-width: 176px;
    padding-left: 25px;
  }
  .blood-count-section .item-label {
    font-size: 20px;
    min-width: 200px;
    max-width: 200px;
  }
  .blood-sugar .accordion-button {
    font-size: 26px;
  }
  .comparison-table table tr td ul {
    padding-left: 40px;
  }
  .comparison-table table tr td ul li {
    font-size: 18px;
  }
  .comparison-table table tr .boldNumber {
    padding-right: 26px;
    padding-left: 8px;
    font-size: 30px;
  }
  .comparison-table table tr td .circle {
    width: 80px;
    height: 80px;
    font-size: 21px;
  }
  .comparison-table table tr th {
    font-size: 20px;
  }
  .pattern-table table tr td {
    font-size: 18px;
  }
  .pattern-table table tr td:first-child {
    font-size: 20px;
    padding-left: 30px;
  }
}
@media (max-width: 1200px) {
  .symptoms ul {
    padding-left: 45px !important;
  }
  .blood-count-section .form-select {
    max-width: 120px;
  }
  .blood-count-section .form-control {
    padding-left: 20px;
  }
  .comparison-table table tr td ul {
    padding-left: 0px;
  }
  .comparison-table {
    width: 850px;
    overflow-x: overlay;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .comparison-table table {
    width: 900px;
  }
}
@media (min-width: 992px) {
  .manual-pdf-section {
    width: 700px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .blood-count-section .blood-detail-section {
    flex-direction: column;
    justify-content: flex-start;
    align-items: self-start !important;
  }
  .blood-count-section .item-label {
    margin-bottom: 12px;
  }
  .blood-count-section .form-select {
    margin-bottom: 16px;
  }
}
@media (max-width: 991px) {
  .header_navbar a .btn {
    margin-top: 20px;
  }
  .blood-count-section {
    padding: 0 28px !important;
  }
  .blood-count-section .item-label {
    min-width: 100% !important;
    max-width: 200px !important;
    font-size: 16px !important;
  }
  .blood-count-section .form-select {
    max-width: 130px;
    padding-left: 18px;
  }
  .upload-pdf ~ span {
    right: -17px !important;
  }
  .functional {
    border-right: 0 !important;
    border-bottom: 1px solid #000;
    padding-bottom: 12px;
    padding-left: 0px !important;
    margin-left: 30px !important;
  }
  .symptoms ul {
    padding-left: 0px !important;
    margin-left: 30px !important;
  }
  .blood-count-accordions .body-section {
    flex-wrap: wrap;
  }
  .blood-count-accordions .body-section .symptoms {
    margin-top: 1rem;
  }
  .blood-count-accordions .accordion-body ul li {
    font-size: 16px;
  }
  .result-accordion {
    margin-top: 0 !important;
  }
  .leftList {
    margin-right: 20px;
    padding-left: 0px;
  }
  .blood-sugar .accordion-body ul li {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .sidebar {
    min-width: unset;
    width: 60px;
    overflow: hidden;
    padding: 0 7px;
  }
  .rws-logo-sidebar {
    padding: 32px 0 0 !important;
    width: 140px;
  }
  .arrow {
    min-width: 50px;
    min-height: 40px;
  }
  .divider {
    margin: 24px 0 !important;
  }
  .nav_item {
    justify-content: center;
    padding: 0 !important;
  }
  .nav_item .sidebar_text {
    display: none;
  }
  .sidebar_icon {
    margin-right: 0 !important;
  }
  .mainDiv > div {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .main-container {
    padding: 0 5px;
    padding-bottom: 20px;
  }
  .main-content {
    padding: 16px 10px;
  }
  .client_search .form-control {
    font-size: 16px;
  }
  .maker-analysis .btn.primary-bg {
    font-size: 14px;
  }
  .maker-analysis .btn,
  .generate-btn {
    margin-bottom: 20px;
  }
  .blood-count-section .blood-detail-section {
    display: block !important;
  }
  .blood-count-section .item-label,
  .blood-count-section .form-select {
    margin-bottom: 12px;
  }
}
@media (max-width: 600px) {
  .scroll-div {
    margin-right: 10px !important;
  }
  .blood-count-section {
    padding-left: 10px !important;
    padding-right: 0 !important;
  }
}
@media (max-width: 575px) {
  .primary-fg {
    font-size: 14px;
  }
  .client_link .cNStyle,
  .client_name,
  .client_search .form-control {
    padding-left: 20px;
  }
  .header_navbar a .btn {
    width: 112px;
  }
  .btn-help {
    margin-right: 16px !important;
  }
  .setting-form .field-btn {
    width: 80px;
    font-size: 14px;
  }
  .upload-pdf,
  .manual-btn {
    width: 100%;
    font-size: 16px;
  }
  .upload-pdf ~ span {
    right: 0px !important;
    top: -30px !important;
  }
  .blood-count-section .blood-detail-section {
    flex-direction: column;
    justify-content: flex-start;
    align-items: self-start !important;
  }
  .blood-count-section .item-label {
    margin-bottom: 12px;
  }
  .blood-count-section .form-select {
    margin-bottom: 16px;
  }
  .blood-count-section .form-select,
  .blood-count-section .form-control {
    padding-left: 12px !important;
  }
  .blood-count-section .section-label {
    font-size: 18px !important;
  }
  .save-submit {
    width: 80% !important;
    margin: auto;
    margin-top: 32px !important;
    display: block;
  }
  .cbc-section {
    padding: 10px;
    padding-left: 0px;
  }
  .functional {
    margin-left: 16px !important;
  }
  .symptoms ul {
    margin-left: 16px !important;
  }
  .blood-count-accordions .accordion-body ul li {
    font-size: 14px;
  }
  .yellow-arrow {
    min-width: 50px;
    max-height: 50px;
    min-height: 50px;
  }
  .yellow-arrow img {
    width: 25px;
  }

  .blood-count-accordions .accordion-body {
    padding: 10px;
  }
  .blood-count-accordions .accordion-button {
    font-size: 16px !important;
    padding: 8px 12px !important;
  }
  .blood-count-accordions .accordion-button img {
    width: 16px;
  }
  .cbc-section .nameLabel {
    font-size: 18px !important;
  }
  .sortSelect .form-select {
    width: 100%;
  }
  .result-accordion .nameLabel {
    font-size: 20px;
  }
  .accordion-section .btn-export {
    width: 100%;
  }
  .sortSelect {
    width: 100%;
  }
  .blood-count-accordions .accordion-body > .d-flex {
    flex-wrap: wrap;
  }
  .functional,
  .symptoms ul {
    margin-left: 0 !important;
  }
  .symptoms {
    width: 100% !important;
  }
  .nameLabel {
    line-height: 30px !important;
  }
  .maker-accordion .accordion-body {
    padding: 10px 10px 20px;
  }
  .maker-accordion .accordion-button,
  .blood-sugar .accordion-button {
    padding: 16px 10px;
  }
  .maker-accordion .accordion-body .btn {
    margin-top: 20px;
  }
  .sortSelect > .mb-3 {
    width: 100%;
  }
  .sortSelect .textfield-label {
    white-space: nowrap;
  }
}
@media (max-width: 550px) {
  .mainInner {
    min-width: 80% !important;
  }
  .blood-sugar .accordion-body {
    padding: 10px;
  }
  .blood-sugar .accordion-body > div {
    flex-wrap: wrap;
  }
  .blood-sugar .accordion-body ul li {
    font-size: 16px;
  }
  .leftList {
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .leftList ~ ul {
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .blood-sugar .login-card {
    padding: 10px;
  }
}
@media (min-width: 451px) {
  .actionButton {
    margin-right: 17px;
  }
  .result_checkboxes {
    padding-left: 32px !important;
    padding-right: 30px !important;
  }
}
@media (max-width: 450px) {
  /* .form_checkbox{
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-left: -10px;
  }
  .form_checkbox .form-check-input{
    margin-left: 6px;
  } */
  .edit_delete {
    display: flex;
    flex-direction: column;
    width: fit-content;
  }
  .edit_delete .btn:first-child {
    margin-bottom: 12px;
    margin-right: 0 !important;
  }
  .edit_delete .btn {
    width: 34px;
    margin-left: auto;
  }
  .result_checkboxes {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .save_action {
    padding-left: 8px !important;
    padding-right: 8px !important;
    font-size: 14px;
  }
}
.login-card.cbc-section {
  margin-bottom: 42px;
}
.print-comparison-table,.print-comparison-title,.print-pattern-comparison-table,.print-pattern-comparison-title,.show-section-print{
  display: none !important; 
}
.comparison-table,.pattern-table,.tableScroll{
  display: block !important;
}
@media print {
  .mainDiv > div{
    padding-top:80px !important;
  }
  html,
  body {
    print-color-adjust: exact;
    background-color: white !important;
  }
  .pagebreak {
    break-after:page
  }
  .cbc-section,.blood-count-accordions .accordion-body,.blood-sugar .login-card{
    padding: 1rem 1.25rem;
  }
  .print-comparison-title,.comparison-accordions .accordion-button,.comparison-accordions .nameLabel,.result-accordion .nameLabel,.maker-accordion .accordion-button,.result-accordion .nameLabel,.blood-sugar .accordion-button,.cbc-section .nameLabel,.blood-count-accordions .accordion-button {
    font-size: 12px;
  }
  /* .maker-accordion {
    margin-bottom: 0px !important;
  } */
  .table.b-table.b-table-stacked>tbody>tr>td{
    font-size: 12px;
  }
  .comparison-table table tr td .circle, .b-table-stacked tr td .circle,.comparison-table table tr td .circle, .b-table-stacked tr td .circle {
    font-size: 11px;
    width: 70px;
    height: 70px;
  }
  .comparison-heading{
    margin-top: 0px;
  }
  
  .comparison-accordions .accordion-body{
    font-size: 12px;
    padding: 19px 1.25rem;
  }
  .result-accordion .nameLabel{
    margin-bottom: 10px !important;
  }
  .mb-26,.login-card.cbc-section {
    margin-bottom: 20px !important;
}
  .blood-sugar .accordion-body ul li,.blood-count-accordions .accordion-body ul li{
    font-size: 12px;
    margin-bottom: 0px !important;
    line-height: 20px !important;
  }
  .blood-sugar .accordion{
    margin-bottom: 15px;
  }
  .blood-sugar .nameLabel {
    padding: 0 !important;
    margin-bottom: 14px !important;
  }
.cbc-section .nameLabel{
  margin-bottom: 14px !important;
}
  .symptoms ul li {
    margin-bottom: 0px !important;
  }
  .arrow {
    min-width: 35px;
    max-height: 35px;
  }
  .arrow-width {
    width: 16px !important;
    height: 16px !important;
  }
  .print-comparison-table,.print-comparison-title,.print-pattern-comparison-table,.print-pattern-comparison-title,.show-section-print{
    display: block !important;
  }
  .comparison-table,.pattern-table,.tableScroll{
    display: none !important;
  }
  .table.b-table.b-table-stacked>tbody>tr>td{
    break-inside: avoid;
  }
  .main-container {
    background-color: white !important;
  }
  .sidebar-section,
  .btn-export,
  .navbar,
  .hide-section-print {
    display: none !important;
  }
  @page {
    size: letter;
    width: 100%;
    height: 100%;
    background-color: #f9fafe !important;
    margin: 32px 0 0 0;
  }
  .pg_break {
    clear: both;
    display: block !important;
    page-break-after: always;
  }
  .blood-count-accordions .otherAccordion .accordion-button,
  .blood-count-accordions .accordion-button.collapsed,
  .login-card {
    box-shadow: none !important;
    border: 1px solid rgb(0 0 0 / 8%) !important;
  }
  .blood-sugar .accordion{
    box-shadow: none !important;
  }
  .blood-count-accordions .body-section {
    flex-wrap: nowrap !important;
  }
  .blood-count-accordions .accordion-body .border-right {
    border-right: 1px solid #000 !important;
    border-bottom: 0px !important;
  }
.report-notes-section{
  font-size: 12px !important;
}
.maker-accordion .accordion-body {
  padding: 0 20px;
  padding-bottom: 20px;
}
  .marker-analysis-accordion,.pattern-analysis-accordion {
    break-inside: avoid;
  }
}
