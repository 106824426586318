html, body {
  height: 100%;
  margin: 0;
}
.wrapper {
  min-height: 100%;
}

.login-card {
  background-color: #ffffff;
  box-shadow: 0px 0px 25px rgba(22, 22, 22, 0.031);
  border-radius: 10px;
}

.rws-login-logo {
  padding: 40px;
  width: 270px;
}
.rws-logo {
  width: 240px;
  padding: 0px 30px 30px;
}

.textfield-label {
  font-weight: 500;
  color: black;
  font-size: 14px;
  margin-bottom: 5px !important;
}

.btn-help,.btn-logout{
  padding: 15px 32px;
  width: fit-content;
}
.btn-auth{
  padding: 18px 30px;
}