@font-face {
  font-family: "Poppins-Bold";
  src: url("../../fonts/Poppins-Bold.ttf");
}
.acSet {
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins-Bold";
}

.text-primary{
  color: #004043 !important
}